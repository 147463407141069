/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { makeHandle as n } from "../core/handleUtils.js";
let o, t;
const e = globalThis.esriConfig?.locale ?? globalThis.dojoConfig?.locale,
  i = globalThis.document?.documentElement;
function r() {
  const n = i?.getAttribute("lang"),
    o = globalThis.navigator?.language;
  return e ?? n ?? o ?? "en";
}
function c() {
  return void 0 === t && (t = r()), t;
}
function a(n) {
  o = n || void 0, b();
}
function l(n = c()) {
  const o = /^([a-zA-Z]{2,3})(?:[_-][a-zA-Z0-9]+)*$/.exec(n);
  return o?.[1].toLowerCase();
}
const s = {
  he: !0,
  ar: !0
};
function u(n = c()) {
  const o = l(n);
  return void 0 !== o && (s[o] || !1);
}
const f = [];
function g(o) {
  return f.push(o), n(() => f.splice(f.indexOf(o), 1));
}
const h = [];
function d(o) {
  return h.push(o), n(() => h.splice(h.indexOf(o), 1));
}
function b() {
  const n = o ?? r();
  t !== n && ([...h].forEach(o => o(n)), t = n, [...f].forEach(o => o(n)));
}
if (globalThis.addEventListener?.("languagechange", b), i) {
  new MutationObserver(() => {
    b();
  }).observe(i, {
    attributeFilter: ["lang"]
  });
}
export { d as beforeLocaleChange, r as getDefaultLocale, l as getLanguage, c as getLocale, g as onLocaleChange, u as prefersRTL, a as setLocale };